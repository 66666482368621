import "./index.scss";
import {
  Form,
  Input,
  Radio,
  Button,
  Upload,
  Spin,
  Card,
  Checkbox,
  message,
} from "antd";
import {
  PlusOutlined,
  LoadingOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../../store";
import { useTranslation } from "react-i18next";
function CampaignCreate({ onChangeTypeHandler, data }) {
  const { uploadStore, fundraiserStore, loginStore } = useStore();
  const { t, i18n } = useTranslation();
  const [userFileList, setUserFileList] = useState([]);
  const [campaignFileList, setCampaignFileList] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [kind, setKind] = useState("campaign/avatar");
  const [uploadType, setUploadType] = useState("avatar");
  const [withCanstruction, setWithCanstruction] = useState(true);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // 改变上传路径
  const onChangeKind = (value) => {
    if (value === "campaign/asset") {
      setUploadType("asset");
    } else {
      setUploadType("avatar");
    }
    setKind(value);
  };

  // 上传图片
  const uploadCustomRequest = async (options) => {
    const form = new FormData();
    form.append("file", options.file);

    try {
      setUploadLoading(true);
      const res = await uploadStore.uploadImage(form, kind);
      let data = {
        id: res.data.id,
        status: "done",
        url: res.data.url,
      };
      if (uploadType === "avatar") {
        setUserFileList([
          {
            ...data,
            name: "avatar",
          },
        ]);
      }

      if (uploadType === "asset") {
        setCampaignFileList([
          ...campaignFileList,
          {
            ...data,
            name: "asset",
          },
        ]);
      }
      setUploadLoading(false);
    } catch (error) {
      setUploadLoading(false);
    }
  };

  const uploadProps = {
    name: "file",
    showUploadList: {
      showPreviewIcon: false,
    },
    customRequest: uploadCustomRequest,
    listType: "picture-circle",
    onRemove: (e) => {
      if (userFileList.find((item) => item.id === e.id)) {
        setUserFileList([]);
      }
      if (campaignFileList.find((item) => item.id === e.id)) {
        let tmpList = [...campaignFileList];
        let index = campaignFileList.findIndex((item) => item.id === e.id);
        tmpList.splice(index, 1);
        setCampaignFileList(tmpList);
      }
    },
    beforeUpload: function (file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error(t("fundraiserPortalCampaignProfileImageType"));
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error(t("fundraiserPortalCampaignInfoWorksImagesSize"));
        return false;
      }
      return isJpgOrPng && isLt2M;
    },
  };

  // 是否有作品
  const onChangeWithCanstruction = (e) => {
    setWithCanstruction(e.target.value);
  };

  const onFinish = async (values) => {
    try {
      // 公共的数据
      let param = {
        name: {
          "zh-tw": values.nameChinese,
          en: values.nameEnglish,
        },
        description: {
          "zh-tw": values.descriptionChinese,
          en: values.descriptionEnglish,
        },
      };

      if (loginStore.userData.category !== "INDIVIDUAL") {
        param = {
          ...param,
          avatarAssetId: userFileList.length ? userFileList[0].id : null,
        };
      }

      if (loginStore.userData.category === "SCHOOL") {
        param = {
          ...param,
          quantity: values.quantity,
          withCanstruction: loginStore.userData
            ? loginStore.userData.withCanstruction
            : values.withCanstruction,
          assetIds: campaignFileList.map((item) => {
            return item.id;
          }),
        };
      }

      setLoading(true);
      // 添加作品
      await fundraiserStore.createCampaign(param);
      setLoading(false);

      onChangeTypeHandler("LIST");
    } catch (error) {
      setLoading(false);
    }
  };

  const [disabled, setDisabled] = useState(true);

  const onChangeAgreeWebsite = () => {
    setDisabled(!disabled);
  };

  return (
    <>
      <Spin spinning={loading}>
        <Card
          size="small"
          bordered={false}
          bodyStyle={{
            boxShadow: "none",
          }}
        >
          <Form
            onFinish={onFinish}
            initialValues={{
              withCanstruction: withCanstruction,
            }}
            layout="vertical"
            form={form}
          >
            <Form.Item
              label={t("fundraiserPortalInfoCampaignNameCN")}
              required
              name="nameChinese"
            >
              <Input></Input>
            </Form.Item>

            <Form.Item
              label={t("fundraiserPortalInfoCampaignNameEN")}
              required
              name="nameEnglish"
            >
              <Input></Input>
            </Form.Item>

            <Form.Item>
              <Button type="primary" disabled>
                {t("btnDonationLink")}
              </Button>
            </Form.Item>

            {loginStore.userData?.category === "INDIVIDUAL" ? null : (
              <Form.Item
                label={t("fundraiserPortalCampaignInfoProfileAvatar")}
                name="profileName"
              >
                <Upload
                  {...uploadProps}
                  fileList={userFileList}
                  onClick={() => onChangeKind("campaign/avatar")}
                >
                  {userFileList.length >= 1 ? null : (
                    <div>
                      {uploadLoading && uploadType == "avatar" ? (
                        <LoadingOutlined />
                      ) : (
                        <PlusOutlined />
                      )}
                      <div
                        style={{
                          marginTop: 8,
                        }}
                      >
                        {t("uploadText")}
                      </div>
                    </div>
                  )}
                </Upload>
              </Form.Item>
            )}

            {data ? (
              <></>
            ) : (
              <>
                {loginStore.userData?.category === "SCHOOL" ? (
                  <Form.Item name="withCanstruction">
                    <Radio.Group onChange={onChangeWithCanstruction}>
                      <Radio value={true}>
                        {t("fundraiserPortalCampaignInfoWithCanstuction")}
                      </Radio>
                      <Radio value={false}>
                        {t("fundraiserPortalCampaignInfoWithoutCanstuction")}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                ) : null}
              </>
            )}

            {withCanstruction && loginStore.userData?.category === "SCHOOL" ? (
              <>
                <Form.Item
                  label={t("fundraiserPortalCampaignInfoQuantity")}
                  name="quantity"
                  rules={[
                    {
                      required: true,
                      message: t(
                        "fundraiserPortalCampaignInfoQuantityErrorRequired"
                      ),
                    },
                  ]}
                >
                  <Input></Input>
                </Form.Item>
                <Form.Item
                  label={t("fundraiserPortalCampaignInfoDescriptionCN")}
                  name="descriptionChinese"
                  required
                >
                  <Input.TextArea></Input.TextArea>
                </Form.Item>
                <Form.Item
                  label={t("fundraiserPortalCampaignInfoDescriptionEN")}
                  name="descriptionEnglish"
                  required
                >
                  <Input.TextArea></Input.TextArea>
                </Form.Item>

                <Form.Item
                  label={t("fundraiserPortalCampaignInfoWorksImages")}
                  name="campaignImage"
                  style={{
                    margin: "0",
                  }}
                >
                  <Upload
                    {...uploadProps}
                    fileList={campaignFileList}
                    onClick={() => onChangeKind("campaign/asset")}
                  >
                    {campaignFileList.length >= 4 ? null : (
                      <div>
                        {uploadLoading && uploadType === "asset" ? (
                          <LoadingOutlined />
                        ) : (
                          <PlusOutlined />
                        )}
                        <div
                          style={{
                            marginTop: 8,
                          }}
                        >
                          {t("uploadText")}
                        </div>
                      </div>
                    )}
                  </Upload>
                </Form.Item>

                <Form.Item>
                  <span
                    style={{
                      color: "#ee1c25",
                    }}
                  >
                    **
                  </span>
                  {t("fundraiserPortalCampaignInfoWorksImagesSize")}
                </Form.Item>

                <Form.Item
                  label={t("fundraiserPortalCampaignInfoWorksVideo")}
                  name="campaignVideo"
                  style={{
                    margin: "0",
                  }}
                >
                  <Input></Input>
                </Form.Item>

                <Form.Item>
                  <span
                    style={{
                      color: "#ee1c25",
                    }}
                  >
                    **
                  </span>
                  {t("fundraiserPortalCampaignInfoWorksVideoSize")}
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item
                  label={t("fundraiserPortalCampaignInfoDescriptionCN")}
                  name="descriptionChinese"
                  required
                >
                  <Input.TextArea></Input.TextArea>
                </Form.Item>
                <Form.Item
                  label={t("fundraiserPortalCampaignInfoDescriptionEN")}
                  name="descriptionEnglish"
                  required
                >
                  <Input.TextArea></Input.TextArea>
                </Form.Item>
              </>
            )}

            <Form.Item
              required
              name="agreeWebsite"
              valuePropName="checked"
              onChange={onChangeAgreeWebsite}
            >
              <Checkbox>
                <span
                  style={{
                    color: "#ee1c25",
                  }}
                >
                  *
                </span>{" "}
                {t("fundraiserPortalCampaignInfoWebsiteTerm")}
              </Checkbox>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                shape="round"
                danger
                className="wide-button"
                htmlType="submit"
                disabled={disabled}
              >
                {t("btnSubmit")}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Spin>
    </>
  );
}
export default observer(CampaignCreate);
