import "./index.scss";
import { Avatar, Button, Image } from "antd";
import { useNavigate } from "react-router-dom";
import JuniorSelectionVoteButtonBlankPng from "../../assets/images/junior_selection_vote_button_blank.png";
import JuniorSelectionVoteButtonVotedPng from "../../assets/images/junior_selection_vote_button_voted.png";
import { useEffect, useState } from "react";
import { getToken } from "../../utils";
import { useStore } from "../../store";
import { useTranslation } from "react-i18next";
function JuniorAvatarComponent({ props }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { fundraiserStore } = useStore();
  const [vote, setVote] = useState(); // 每一个campaign的vote量，用来不刷新界面，自动增长
  useEffect(() => {
    let obj = {};
    obj[props.id] = props.voteAmount || 0;
    setVote({
      ...obj,
    });
  }, [props]);

  // 添加投票
  const postVoteRecord = async (campaignId) => {
    let token = getToken(campaignId);
    let obj = { ...vote };

    if (!token) {
      await fundraiserStore.createVoteRecord({ campaignId });
      obj[campaignId] = obj[campaignId] + 1;
    } else {
      await fundraiserStore.deleteVoteRecord({ id: token, campaignId });
      obj[campaignId] = obj[campaignId] - 1;
    }

    setVote({
      ...obj,
    });
  };

  return (
    <div className="junior-avatar-component">
      <div>
        <Avatar
          className="component-img"
          size={{
            xs: 100,
            sm: 120,
            md: 140,
            lg: 160,
            xl: 180,
            xxl: 200,
          }}
          src={props.avatarSrc}
        ></Avatar>
      </div>

      <div className="component-detail">
        <h4 className="detail-name">
          {props.name[i18n.language.replace("_", "-")]}
        </h4>

        {props.raised !== undefined ? (
          <h4 className="detail-raised">
            {t("raisedText")} HK$
            {isNaN(props.raised)
              ? props.raised
              : props.raised.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </h4>
        ) : null}
        {/* {props.organizer !== undefined ? (
          <h5 className="detail-organizer">
            {t("organizerText")}: {props.organizer}
          </h5>
        ) : null} */}

        <div className="detail-favorite">
          <Image
            src={
              getToken(props.id)
                ? JuniorSelectionVoteButtonVotedPng
                : JuniorSelectionVoteButtonBlankPng
            }
            width={40}
            onClick={() => postVoteRecord(props.id)}
            preview={false}
            style={{
              cursor: "pointer",
            }}
          ></Image>
          <h4 className="favorite-amount">
            {" "}
            &nbsp;{vote ? vote[props.id] : null}{" "}
            {vote ? (
              <span
                style={{
                  fontSize: "14px",
                }}
              >
                {t("homeVotedText")}
              </span>
            ) : null}
          </h4>
        </div>
        <div className="detail-button">
          <Button
            type="primary"
            shape="round"
            danger
            className="learn-more-button"
            onClick={(e) => {
              e.preventDefault();
              navigate("/campaign/" + props.id);
            }}
            href={"/campaign/" + props.id}
          >
            {t("btnLearnMore")}
          </Button>
        </div>
      </div>
    </div>
  );
}
export default JuniorAvatarComponent;
