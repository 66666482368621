import {
  Radio,
  Divider,
  Spin,
  Card,
  Space,
  Select,
  Typography,
  Empty,
} from "antd";
import "./index.scss";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../store";
import { useEffect, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
function FundraiserInfomationManagerHistory() {
  const { loginStore, fundraiserStore, donateStore } = useStore();
  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [currentCampaign, setCurrentCampaign] = useState();
  const [radioId, setRadioId] = useState();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    (async () => {
      setLoading(true);

      try {
        //　查询是否作品，决定头顶列表
        let userId = loginStore.userData.id;
        let category = loginStore.userData.category;
        if (category === "SCHOOL") {
          let campaigns = await fundraiserStore.getCampaign({
            userId,
            isPublic: false,
            withCanstruction: true,
          });
          if (campaigns.data.data.length) {
            // 作品列表
            let list = [];
            campaigns.data.data.map((item) => {
              let tmp = {
                id: item.id,
                name: item.name,
                donationAmount:
                  item.donationAmount === null ? "******" : item.donationAmount,
                donorAmount:
                  item.donorAmount === null ? "******" : item.donorAmount,
              };
              list.push(tmp);
            });
            setCampaignList(list);
            // 默认第一个活动
            setCurrentCampaign(campaigns.data.data[0]);
            // 查询记录
            await getDonateRecordFunc(campaigns.data.data[0].id);
          }
        } else {
          let campaigns = await fundraiserStore.getCampaign({
            userId,
            isPublic: false,
          });
          if (campaigns.data.data.length) {
            // 查询记录
            setCurrentCampaign(campaigns.data.data[0]);
            await getDonateRecordFunc(campaigns.data.data[0].id);
          }
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  // 选中作品
  const onChangeCampaignHanlder = async (e) => {
    setLoading(true);
    // 改变当前活动
    let data = campaignList.find((item) => item.id === e.target.value);
    if (data) {
      setCurrentCampaign(data);
      await getDonateRecordFunc(data.id);
    }
    setLoading(false);
  };

  // 查询参数
  const [search, setSearch] = useState({
    sort: "createdAt",
    order: "desc",
    status: "COMPLETED",
  });

  // 改变排序
  const onChangeSearchHandler = (value) => {
    let str = {
      ...search,
    };
    switch (value) {
      case "Amount":
        str = {
          ...str,
          sort: "amount",
          order: "desc",
        };
        break;
      case "Lastest":
        str = {
          ...str,
          sort: "createdAt",
          order: "desc",
        };
        break;
      case "Oldest":
        str = {
          ...str,
          sort: "createdAt",
          order: "asc",
        };
        break;
    }
    setSearch({
      ...str,
    });
  };

  useEffect(() => {
    try {
      if (currentCampaign) {
        getDonateRecordFunc(currentCampaign?.id);
      }
    } catch (error) {
      console.log(error);
    }
  }, [search]);

  // 获取捐款记录
  const getDonateRecordFunc = async (camId) => {
    setRadioId(camId);

    let records = await donateStore.getDonateRecord({
      campaignId: camId,
      ...search,
    });
    if (records.data.data.length) {
      let list = [];
      for (let item of records.data.data) {
        list.push({
          code: item.code,
          name: (item.firstName || "") + " " + (item.surname || ""),
          date: moment(item.createdAt).format("DD-MM-YYYY"),
          amount: item.amount || "******",
          status:
            item.status.toLowerCase().charAt(0).toUpperCase() +
            item.status.toLowerCase().slice(1),
        });
      }

      setRecordData([...list]);
    }
  };
  return (
    <>
      <Spin spinning={loading}>
        <div className="fundraiser-donate-history">
          {loginStore.userData.category === "SCHOOL" && campaignList.length ? (
            <Card>
              <Radio.Group onChange={onChangeCampaignHanlder} value={radioId}>
                <Space direction="horizontal">
                  {campaignList.map((item, index) => {
                    return (
                      <Radio value={item.id} key={index}>
                        {item.name[i18n.language.replace("_", "-")]}
                      </Radio>
                    );
                  })}
                </Space>
              </Radio.Group>
            </Card>
          ) : null}

          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              width: "100%",
              textAlign: "center",
            }}
          >
            <Typography.Title level={2}>
              HK${currentCampaign?.donationAmount || 0}
            </Typography.Title>
            <Typography.Paragraph>
              {t("hasBeenFundedText")}
            </Typography.Paragraph>

            <Typography.Title level={2}>
              {currentCampaign?.donorAmount || 0}
            </Typography.Title>
            <Typography.Paragraph>{t("donorText")}</Typography.Paragraph>
          </div>

          <Card
            size="small"
            title={
              <Typography.Title level={3}>
                {t("donationHistoryListTitle")}
              </Typography.Title>
            }
            bordered={false}
            extra={
              <Select
                defaultValue="Lastest"
                onChange={onChangeSearchHandler}
                style={{
                  width: 240,
                }}
                options={[
                  {
                    value: "Amount",
                    label: t("donationHistoryDonationAmount"),
                  },
                  {
                    value: "Lastest",
                    label: t("donationHistoryLastest"),
                  },
                  {
                    value: "Oldest",
                    label: t("donationHistoryOldest"),
                  },
                ]}
              />
            }
          >
            {recordData.length ? (
              <>
                {recordData.map((item, index) => {
                  return (
                    <div key={index}>
                      <Typography.Paragraph>
                        {t("donationHistoryDetailCode")}: {item.code}
                      </Typography.Paragraph>

                      <Typography.Paragraph>
                        {t("donationHistoryDetailDate")}: {item.date}
                      </Typography.Paragraph>

                      <Typography.Paragraph>
                        {t("donationHistoryDetailDonorName")}: {item.name}
                      </Typography.Paragraph>

                      <Typography.Paragraph>
                        {t("donationHistoryDetailAmount")}: ${item.amount}
                      </Typography.Paragraph>

                      <Typography.Paragraph>
                        {t("donationHistoryDetailStatus")}: {item.status}
                      </Typography.Paragraph>
                      <Divider></Divider>
                    </div>
                  );
                })}
              </>
            ) : (
              <Empty description={false} />
            )}
          </Card>
        </div>
      </Spin>
    </>
  );
}

export default observer(FundraiserInfomationManagerHistory);
