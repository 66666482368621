import { Divider } from "antd";
import "./index.scss";
import { useTranslation } from "react-i18next";
function FundraiserCampaignIndividual({ data }) {
  const { t, i18n } = useTranslation();
  return (
    <div className="fundraiser-campaign-individual">
      <h2 className="content-detail-title">{data?.name[i18n.language.replace('_','-')]}</h2>
      <Divider className="divider-line" />
      <h3 className="content-detail-description">
        {data?.description[i18n.language.replace('_','-')]}
      </h3>
      <Divider className="divider-line" />
      <div className="content-detail-info">
        <div>
          HK$
          {isNaN(data?.donationAmount)
            ? data?.donationAmount
            : data?.donationAmount
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
        <div className="content-detail-info-second">{t("hasBeenFundedText")}</div>
        <div className="content-detail-info-amount">{data?.donorAmount}</div>
        <div className="content-detail-info-donor">{t("donorText")}</div>
      </div>
    </div>
  );
}

export default FundraiserCampaignIndividual;
