import "./index.scss";
import { observer } from "mobx-react-lite";
import RegistionPage from "./Registion";
import DonationPage from "./Donation";
import { useEffect } from "react";
import { useStore } from "../../../../store";
import { Divider } from "antd";
function FundraiserAccountInformation() {
  const { loginStore } = useStore();

  return (
    <div className="fundraiser-account-information">
      <RegistionPage />
      {loginStore.userData?.category !== "SCHOOL" ? (
        <>
          <Divider />
          <DonationPage />
        </>
      ) : null}
    </div>
  );
}

export default observer(FundraiserAccountInformation);
