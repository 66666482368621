import "./index.scss";
import { Avatar, Image, Divider, Space, Typography, Row, Col } from "antd";
import { HeartFilled } from "@ant-design/icons";
import YouTube from "react-youtube";
import cookie from "react-cookies";
import CanUsedIcon from "../../../../assets/images/can_used_icon.png";
import JuniorPageVotedIcon from "../../../../assets/images/junior_page_voted_icon.png";
import { useTranslation } from "react-i18next";
function FundraiserCampaignJunior({ data }) {
  const { t, i18n } = useTranslation();

  let videoUrl = data?.videoUrl
    ? data?.videoUrl.match(/[\/=]([a-zA-Z0-9-_]{11})/) || null
    : null;
  const youtubeVideoId =
    videoUrl && videoUrl.length ? videoUrl[1] || null : null;
  console.log("youtubeVideoId", youtubeVideoId);

  return (
    <div className="fundraiser-campaign-junior">
      <div>
        <div>
          <Avatar
            className="content-avatar"
            size={{
              xs: 100,
              sm: 120,
              md: 140,
              lg: 160,
              xl: 180,
              xxl: 200,
            }}
            src={data?.avatarUrl}
          ></Avatar>
        </div>
        <h2 className="content-detail-title">
          {data?.name[i18n.language.replace("_", "-")]}
        </h2>
        {/* <h5 className="content-detail-organizer">
          {t("organizerText")}: {data?.companyName || ""}
        </h5> */}
        <Divider className="divider-line" />

        {youtubeVideoId && (
          <YouTube videoId={youtubeVideoId} className="youtube-container" />
        )}

        <div className="content-image-container">
          <Row gutter={8}>
            {data?.assets.map((url, index) => {
              return (
                <Col key={index} span={6}>
                  <Image src={url} width="100%" height={120} />
                </Col>
              );
            })}
          </Row>
        </div>

        {data?.assets.length ? <Divider className="divider-line" /> : null}
      </div>

      <div className="content-detail-can-info">
        <Image src={CanUsedIcon} height={64} width={64} preview={false} />
        <div className="content-detail-info-amount">{data?.quantity}</div>
        <div className="content-detail-info-donor">{t("cansUsedText")}</div>
      </div>

      <Divider className="divider-line" />
      <div className="content-detail-description">
        {data?.description[i18n.language.replace("_", "-")]}
      </div>

      <Divider className="divider-line" />
      <div className="content-detail-info">
        <div>
          HK$
          {isNaN(data?.donationAmount)
            ? data?.donationAmount
            : data?.donationAmount
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
        <div className="content-detail-info-second">
          {t("hasBeenFundedText")}
        </div>

        <div className="content-detail-info-amount">
          <Space>
            <Image
              src={JuniorPageVotedIcon}
              height={64}
              width={64}
              preview={false}
            />
            <Typography.Title>{data?.voteAmount}</Typography.Title>
          </Space>
        </div>
        <div className="content-detail-info-donor">{t("votedText")}</div>

        <div className="content-detail-info-amount">{data?.donorAmount}</div>
        <div className="content-detail-info-donor">{t("donorText")}</div>
      </div>
    </div>
  );
}

export default FundraiserCampaignJunior;
