import "./index.scss";
import {
  Modal,
  Button,
  Image,
  Typography,
  Space,
  Divider,
  Col,
  Row,
  Avatar,
} from "antd";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { CheckCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../store";

import paymeLogo from "../../assets/images/payment-payme-header.png";

function DonationPayment() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [params] = useSearchParams();
  const { donateStore, loginStore } = useStore();
  const timeRef = useRef();
  const timeoutRef = useRef(); // 60s後顯示取消支付
  const timeoutErrorRef = useRef(); // 10分鍾後停止查詢並且失敗
  const [isOut, setIsOut] = useState(false); // 是否超時了
  const [cancelBtn, setCancelBtn] = useState(false);
  const [isCancel, setIsCancel] = useState(false);

  const cancelRef = useRef(false);

  const [pathParam, setPathParam] = useState(); // 路径数据
  const [status, setStatus] = useState(null); // 支付状态

  async function searchDonationFunc(donationRecordId) {
    try {
      if (cancelRef.current) return;
      let res = await donateStore.getDonateRecordById({
        id: donationRecordId,
      });
      setStatus(res.data.status);

      if (res.data.status === "COMPLETED") {
        clearTimeout(timeRef.current);
        clearTimeout(timeoutRef.current);
        clearTimeout(timeoutErrorRef.current);
        timeRef.current = null;
        timeoutRef.current = null;
        timeoutErrorRef.current = null;
        return;
      }
      clearTimeout(timeRef.current);
      timeRef.current = setTimeout(() => {
        searchDonationFunc(donationRecordId);
      }, 1000);
    } catch (error) {
      timeRef.current = null;
      timeoutRef.current = null;
      timeoutErrorRef.current = null;
    }
  }

  useEffect(() => {
    (async () => {
      if (timeRef.current) {
        clearTimeout(timeRef.current);
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      if (timeoutErrorRef.current) {
        clearTimeout(timeoutErrorRef.current);
      }
      let donationRecordId = params.get("donationRecordId");
      let paymentType = params.get("paymentType");
      let target = params.get("target");
      let userId = params.get("userId");
      let campaignId = params.get("campaignId");
      setPathParam({
        donationRecordId,
        paymentType,
        target,
        userId,
        campaignId,
      });

      if (
        donationRecordId &&
        (paymentType === "PAYME" || paymentType === "PAYPAL")
      ) {
        // 查询结果
        searchDonationFunc(donationRecordId);
        if (paymentType === "PAYME") {
          //一分鍾後可以取消
          timeoutRef.current = setTimeout(() => {
            setCancelBtn(true);
          }, 60 * 1000);

          timeoutErrorRef.current = setTimeout(function () {
            cancelRef.current = true;
            setIsOut(true);
          }, 10 * 60 * 1000);
        }
      }

      if (paymentType === "CHEQUE" || paymentType === "BANK") {
        setStatus("COMPLETED");
      }
    })();
  }, []);

  async function goToTarget() {
    if (pathParam && pathParam.target) {
      let target = "";
      if (pathParam.target === "fundraiser-register") {
        target = `/${pathParam.target}?userId=${pathParam.userId}`;
      }

      if (pathParam.target === "support-us") {
        target = `/${pathParam.target}`;
      }

      if (pathParam.target === "campaign") {
        target = `/${pathParam.target}/${pathParam.campaignId}`;
      }

      if (pathParam.target === "portal") {
        await loginStore.checkAuth(); // 刷新登陆信息
        target = `/${pathParam.target}?userId=${pathParam.userId}`;
      }

      navigate(target, {
        replace: true,
      });
    }
  }

  // 回退
  async function cancelPaymeHandler() {
    clearTimeout(timeRef.current);
    clearTimeout(timeoutRef.current);
    clearTimeout(timeoutErrorRef.current);
    timeoutErrorRef.current = null;
    timeRef.current = null;
    timeoutRef.current = null;
    cancelRef.current = true;
    window.history.back();
  }
  return (
    <>
      <Modal
        className="payment-modal"
        keyboard={false}
        centered
        closeIcon={false}
        open={true}
        width={800}
        title={
          <div
            style={{
              textAlign: "center",
            }}
          >
            {t("donationPaymentTitle")}
          </div>
        }
        footer={
          status === "COMPLETED"
            ? [
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Button type="primary" onClick={goToTarget}>
                    {t("btnBack")}
                  </Button>
                </div>,
              ]
            : null
        }
      >
        {pathParam?.paymentType === "PAYME" ? (
          <div>
            <Image src={paymeLogo} preview={false} height={45} />
          </div>
        ) : null}
        <div className="donation-payment-content">
          <div>
            {pathParam?.paymentType === "PAYME" ? (
              <>
                {status === "PENDING" ? (
                  <>
                    {location?.state?.isMobile ? (
                      <Space direction={"vertical"}>
                        <Typography.Text>
                          {isOut
                            ? t("paymeIsTimeout")
                            : t("donationPaymentPaymeWaitingPaymentMessage")}
                        </Typography.Text>
                        {cancelBtn ? (
                          <Button
                            type="primary"
                            shape="round"
                            danger
                            onClick={cancelPaymeHandler}
                          >
                            {t("btnCancel")}
                          </Button>
                        ) : null}
                      </Space>
                    ) : (
                      <Space direction={"vertical"}>
                        <h3>{t("donationPaymentPayMeTitle")}</h3>
                        <div className="close-out-icon">
                          {isOut ? <CloseOutlined /> : null}
                        </div>

                        <Image
                          src={location?.state?.paymeUrl}
                          preview={false}
                          height={320}
                          className={[isOut ? "payme-code-img" : null]}
                        ></Image>

                        <Typography.Text type="secondary">
                          {isOut
                            ? t("paymeIsTimeout")
                            : t("donationPaymentPayMeBottom")}
                        </Typography.Text>
                        {cancelBtn ? (
                          <Button
                            type="primary"
                            shape="round"
                            danger
                            onClick={cancelPaymeHandler}
                          >
                            {t("btnCancel")}
                          </Button>
                        ) : null}

                        <Divider
                          style={{
                            marginBottom: 8,
                            marginTop: cancelBtn ? 8 : "24px",
                          }}
                        />
                        <div style={{ textAlign: "left", marginBottom: 16 }}>
                          {t("donationPaymentPayMeStepTitle")}
                        </div>
                        <Row gutter={1}>
                          <Col span={8}>
                            <Space align="start">
                              <Avatar size="small">1</Avatar>
                              <div style={{ textAlign: "left" }}>
                                {t("donationPaymentPayMeStep1")}
                              </div>
                            </Space>
                          </Col>
                          <Col span={8}>
                            <Space align="start">
                              <Avatar size="small">2</Avatar>
                              <div style={{ textAlign: "left" }}>
                                {t("donationPaymentPayMeStep2")}
                              </div>
                            </Space>
                          </Col>
                          <Col span={8}>
                            <Space align="start">
                              <Avatar size="small">3</Avatar>
                              <div style={{ textAlign: "left" }}>
                                {t("donationPaymentPayMeStep3")}
                              </div>
                            </Space>
                          </Col>
                        </Row>
                      </Space>
                    )}
                  </>
                ) : (
                  <>
                    {/* 支付失敗, 預留之後使用 */}
                    {/*<div style={{ fontSize: 120, color: '#b52a2a' }}>
                      <CloseCircleOutlined />
                    </div>*/}
                    <div style={{ fontSize: 120, color: "#3cb52a" }}>
                      <CheckCircleOutlined />
                    </div>
                    <Typography.Title level={5} style={{ marginBottom: 24 }}>
                      {t("donationPaymentSuccessMessage")}
                    </Typography.Title>
                  </>
                )}
              </>
            ) : null}

            {pathParam?.paymentType === "CHEQUE" ||
            pathParam?.paymentType === "BANK" ? (
              <Typography>
                <Typography.Title>
                  {t("donationPaymentBankSuccess")}
                </Typography.Title>
                <Typography.Paragraph>
                  {t("donationPaymentBankCode")}:{" "}
                  {location?.state?.confirmationNumber}
                </Typography.Paragraph>
                <Typography.Paragraph>
                  {t("donationPaymentBankMessage")}
                </Typography.Paragraph>
              </Typography>
            ) : null}

            {pathParam?.paymentType === "PAYPAL" ? (
              <>
                <Typography.Title>
                  {status === "COMPLETED"
                    ? t("donationPaymentPaypalSuccess")
                    : t("donationPaymentPaypalWaiting")}
                </Typography.Title>
              </>
            ) : null}
          </div>
        </div>
      </Modal>
    </>
  );
}
export default DonationPayment;
