import { Spin } from "antd";
import "./index.scss";
import { observer } from "mobx-react-lite";
import CampaignCreatePage from "./Campaign_Create";
import CampaignListPage from "./Campaign_List";
import CampaignEditPage from "./Campaign_Edit";
import { useStore } from "../../../../store";
import { useEffect, useState } from "react";

function FundraiserInfomationManagerSetting() {
  const [campaignType, setCampaignType] = useState("LIST");

  const [data, setData] = useState(null); // 数据

  // 其他层面进行添加或者编辑
  async function onChangeTypeHandler(type, data) {
    setCampaignType(type);
    setData(data);
  }

  return (
    <div className="campaign-setting">
      {campaignType === "CREATE" ? (
        <CampaignCreatePage
          onChangeTypeHandler={onChangeTypeHandler}
          data={data}
        ></CampaignCreatePage>
      ) : null}

      {campaignType === "LIST" ? (
        <CampaignListPage onChangeTypeHandler={onChangeTypeHandler} />
      ) : null}

      {campaignType === "EDIT" ? (
        <CampaignEditPage
          data={data}
          onChangeTypeHandler={onChangeTypeHandler}
        />
      ) : null}
    </div>
  );
}

export default observer(FundraiserInfomationManagerSetting);
