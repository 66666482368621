import "./index.scss";
import { observer } from "mobx-react-lite";
import {
  Typography,
  Space,
  Button,
  Divider,
  Row,
  Col,
  Modal,
  Card,
  Form,
  Input,
  Radio,
  message,
  Spin,
} from "antd";
import { FormOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useStore } from "../../../../../store";
import { useTranslation } from "react-i18next";

const { Paragraph, Title, Text } = Typography;

const categorys = {
  INDIVIDUAL: "Individual",
  CORPORATE: "Corporate",
  SCHOOL: "School",
};

function FundraiserPortalAccountRegistion() {
  const { loginStore } = useStore();
  const [detailOpen, setDetailOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();

  // 打开详情
  const openDetailHandle = async () => {
    setDetailOpen(!detailOpen);
    setEditOpen(false);
  };

  // 打开编辑页
  const openEditModalHandle = async () => {
    setDetailOpen(false);
    setEditOpen(!editOpen);
  };

  // 提交
  async function onFinish(values) {
    try {
      setLoading(true);
      await loginStore.editUser({
        ...values,
      });

      await loginStore.checkAuth();

      // 默认语言en,以登录为准
      i18n.changeLanguage(
        loginStore.userData?.locale.replace("-", "_") || "en"
      );

      message.success(t("submitSuccessMessage"));
      openDetailHandle();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      message.success(t("submitFailedMessage"));
    }
  }

  return (
    <Card
      title={t("registrationTitle")}
      bordered={false}
      size="small"
      extra={
        <Space>
          {loginStore.userData?.isApproved ? (
            <>
              <Button
                type="primary"
                shape="round"
                danger
                className="wide-button link-button btn-white-font "
                onClick={openDetailHandle}
              >
                {detailOpen ? t("btnClose") : t("btnDetail")}
              </Button>
              <FormOutlined
                style={{
                  fontSize: "2.2em",
                  cursor: "pointer",
                }}
                onClick={openEditModalHandle}
              ></FormOutlined>
            </>
          ) : (
            <Button type="text" disabled>
              {t("fundraiserPendingText")}
            </Button>
          )}
        </Space>
      }
    >
      <Paragraph>
        <label>
          {detailOpen ? null : t("fundraiserPortalAccountRegistionSuccess")}
        </label>
      </Paragraph>

      {detailOpen ? (
        <>
          <Paragraph
            style={{
              margin: "0",
            }}
          >
            {t("registerFundraiserCategory")}:
          </Paragraph>
          <Paragraph strong>
            {categorys[loginStore.userData?.category]}
          </Paragraph>

          {loginStore.userData?.category !== "INDIVIDUAL" ? (
            <>
              <Typography.Paragraph
                style={{
                  margin: "0",
                }}
              >
                {t("fundraiserPortalAccountRegistionCompanyName")}:
              </Typography.Paragraph>

              <Typography.Paragraph strong>
                {loginStore.userData?.companyName || ""}
              </Typography.Paragraph>
            </>
          ) : null}

          <Paragraph
            style={{
              margin: "0",
            }}
          >
            {t("supportUsInfoEmail")}:
          </Paragraph>
          <Paragraph strong>{loginStore.userData?.email}</Paragraph>

          <Paragraph
            style={{
              margin: "0",
            }}
          >
            {t("registerFundraiserContactPerson")}
          </Paragraph>
          <Paragraph strong>{loginStore.userData?.contactPerson}</Paragraph>

          <Paragraph
            style={{
              margin: "0",
            }}
          >
            {t("registerFundraiserContactNumber")}
          </Paragraph>
          <Paragraph strong>{loginStore.userData?.contactNumber}</Paragraph>

          <Paragraph
            style={{
              margin: "0",
            }}
          >
            {t("registerFundraiserLanguage")}:
          </Paragraph>
          <Paragraph strong>
            {loginStore.userData?.locale === "zh-tw" ? "繁體中文" : "Eng"}
          </Paragraph>
        </>
      ) : null}

      {editOpen ? (
        <>
          <Spin spinning={loading}>
            <Form
              layout="vertical"
              size="large"
              onFinish={onFinish}
              initialValues={{
                locale: loginStore.userData?.locale,
                contactPerson: loginStore.userData?.contactPerson,
                contactNumber: loginStore.userData?.contactNumber,
              }}
            >
              <Form.Item
                label={t("registerFundraiserContactPerson")}
                rules={[
                  {
                    required: true,
                    message: t("registerFundraiserContactPersonErrorRequired"),
                  },
                ]}
                name="contactPerson"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={t("registerFundraiserContactNumber")}
                rules={[
                  {
                    required: true,
                    message: t("registerFundraiserContactNumberErrorRequired"),
                  },
                ]}
                name="contactNumber"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={t("registerFundraiserLanguage")}
                required
                name="locale"
              >
                <Radio.Group>
                  <Radio value={"zh-tw"}>繁體中文</Radio>
                  <Radio value={"en"}>English</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  shape="round"
                  danger
                  className="wide-button link-button "
                  htmlType="submit"
                >
                  {t("btnSubmit")}
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </>
      ) : null}
    </Card>
  );
}

export default observer(FundraiserPortalAccountRegistion);
