import "./index.scss";
import { Row, Col, Form, Button, Input, Radio, Divider, Spin } from "antd";

import DefaultAdvertSlider from "../../Home/Default_Advert_Slider";
import DefaultCampaignDate from "../../Home/Default_Campaign_Date";

import FundraiserCampaignCorporate from "./Corporate";
import FundraiserCampaignIndividual from "./Individual";
import FundraiserCampaignJunior from "./Junior";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../store";
import { useTranslation } from "react-i18next";
function FundraiserCampaign() {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const { fundraiserStore, donateStore, homeStore } = useStore();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    homeStore.currentNav = "";
    (async () => {
      try {
        setLoading(true);

        let result = {};
        // 获取campaign
        let campaignData = await fundraiserStore.getCampaignById({
          id: params.campaignId,
          isPublic: true,
        });

        if (campaignData.data) {
          // 获取头像
          let avatarUrl = campaignData.data.avatarAssetId
            ? await fundraiserStore.getAssetUrl({
                id: campaignData.data.avatarAssetId,
              })
            : null;

          // 获取每个素材的图片
          let assetsUrl = [];
          if (campaignData?.data?.assetIds) {
            for (let assetId of campaignData?.data?.assetIds) {
              let url = assetId
                ? await fundraiserStore.getAssetUrl({
                    id: assetId,
                  })
                : null;
              assetsUrl.push(url);
            }
          }

          result = {
            name: campaignData.data.name,
            description: campaignData.data.description,
            donationAmount:
              campaignData.data.donationAmount === null
                ? "******"
                : campaignData.data.donationAmount,
            donorAmount:
              campaignData.data.donorAmount === null
                ? "******"
                : campaignData.data.donorAmount,
            avatarUrl,
            assets: assetsUrl,
            category: campaignData.data.category,
            voteAmount: campaignData.data.voteAmount,
            quantity: campaignData.data.quantity,
            companyName: campaignData.data.companyName || "",
            videoUrl: campaignData.data.videoUrl,
          };
          setData({ ...result });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error.response?.data === "NOT_FOUND") {
          navigate("/message", {
            state: {
              content: "campaignNotFound",
            },
          });
        }
      }
    })();
  }, []);

  // 去支付
  const goToDonation = (e) => {
    e.preventDefault();
    navigate("/support-us?campaignId=" + params.campaignId);
  };

  return (
    <>
      <div className="fundraiser-campaign">
        <div className="container">
          <h2 className="content-title">
            {data?.category === "CORPORATE"
              ? t("fundraiserCampaignCorporateTitle")
              : null}
            {data?.category === "INDIVIDUAL"
              ? t("fundraiserCampaignIndividualTitle")
              : null}
            {data?.category === "SCHOOL"
              ? t("fundraiserCampaignSchoolTitle")
              : null}
          </h2>
        </div>

        <div className="content-detail">
          <div className="container">
            <Spin spinning={loading}>
              {data ? (
                <>
                  {data?.category === "CORPORATE" ? (
                    <FundraiserCampaignCorporate data={data} />
                  ) : null}
                  {data?.category === "INDIVIDUAL" ? (
                    <FundraiserCampaignIndividual data={data} />
                  ) : null}
                  {data?.category === "SCHOOL" ? (
                    <FundraiserCampaignJunior data={data} />
                  ) : null}
                  {data ? (
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        shape="round"
                        size="large"
                        danger
                        className="support-button"
                        onClick={(e) => goToDonation(e)}
                        href={"/support-us?campaignId=" + params.campaignId}
                      >
                        {t("btnSupportDonate")}
                      </Button>
                    </div>
                  ) : null}
                </>
              ) : (
                <div
                  style={{
                    height: "5em",
                  }}
                ></div>
              )}
            </Spin>
          </div>
        </div>
      </div>
    </>
  );
}

export default FundraiserCampaign;
