import "./index.scss";
import { Row, Col, Select, Spin, Space, Button } from "antd";
import DefaultAdvertSlider from "../../Home/Default_Advert_Slider";
import DefaultCampaignDate from "../../Home/Default_Campaign_Date";
import FundraiserHomeComponent from "../../../components/FundraiserHomeComponent";
import { useEffect, useState } from "react";
import { useStore } from "../../../store";
import { useTranslation } from "react-i18next";
function FundraiserHome() {
  const { fundraiserStore, loginStore } = useStore();
  const { t } = useTranslation();
  const [currentNav, setCurrentNav] = useState("CORPORATE");
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  // 查询参数
  const [search, setSearch] = useState({
    sort: "donationAmount",
    order: "desc",
    isPublic: true,
    category: "CORPORATE",
  });

  const choiceNavHandler = (navName) => {
    setCurrentNav(navName);
    setSearch({
      ...search,
      category: navName,
    });
  };

  // 改变排序
  const onChangeSearchHandler = (value) => {
    let str = { ...search };
    switch (value) {
      case "Lastest":
        str = {
          ...str,
          sort: "createdAt",
          order: "desc",
        };
        break;
      case "Oldest":
        str = {
          ...str,
          sort: "createdAt",
          order: "asc",
        };
        break;
      case "Donate":
        str = {
          ...str,
          sort: "donationAmount",
          order: "desc",
        };
        break;
      case "Popular":
        str = {
          ...str,
          sort: "voteAmount",
          order: "desc",
        };
        break;
    }
    setSearch({
      ...str,
    });
  };

  // 查询活动
  const searchCampaigns = async () => {
    setLoading(true);
    let campaignDatas = await fundraiserStore.getCampaign(search);
    let list = [];
    if (campaignDatas.data.count) {
      for (let item of campaignDatas.data.data) {
        let obj = {
          avatarSrc: item.avatarAssetId
            ? await fundraiserStore.getAssetUrl({
                id: item.avatarAssetId,
              })
            : null,
          name: item.name,
          raised: item.donationAmount === null ? "******" : item.donationAmount,
          voteAmount: item.voteAmount,
          id: item.id,
          description: item.description,
          type: currentNav,
        };
        list.push(obj);
      }
    }

    var arr = [];

    for (var i = 0; i < list.length; i += 2) {
      arr.push(list.slice(i, i + 2));
    }

    setData(arr);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      try {
        searchCampaigns();
      } catch (error) {
        console.log(error);
      }
    })();
  }, [search]);

  // check mobile
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <div className="fundraiser-home">
      <div className="content-title-container">
        <Space>
          <h2 className="content-title">{t("menuFundraisers")}</h2>
          <Select
            defaultValue="Donate"
            size={isMobile ? "small" : "large"}
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "16em",
            }}
            onChange={onChangeSearchHandler}
            options={[
              {
                value: "Lastest",
                label: t("lastestText"),
              },
              {
                value: "Oldest",
                label: t("oldestText"),
              },
              {
                value: "Donate",
                label: t("top10MostCashDonated"),
              },
              {
                value: "Popular",
                label: t("top10MostPopular"),
              },
            ]}
          />
        </Space>
      </div>

      <div className="content-nav">
        <div className="container">
          <Row>
            <Col
              span={12}
              className={[
                "nav-title",
                currentNav === "CORPORATE" ? "active" : null,
              ]}
              onClick={() => choiceNavHandler("CORPORATE")}
            >
              <h1>{t("fundraiserCorporateTitle")}</h1>
            </Col>
            <Col
              span={12}
              className={[
                "nav-title",
                currentNav === "INDIVIDUAL" ? "active" : null,
              ]}
              onClick={() => choiceNavHandler("INDIVIDUAL")}
            >
              <h1>{t("fundraiserIndividualTitle")}</h1>
            </Col>
          </Row>
        </div>
      </div>

      <Spin spinning={loading}>
        <div
          className="container"
          style={{
            paddingTop: !loginStore.userData ? "20px" : "0",
          }}
        >
          {!loginStore.userData ? (
            <Button
              type="link"
              href="/fundraiser-login"
              style={{
                fontWeight: "bold",
              }}
            >
              {t("fundraiserHomeNotLoginGoRegsiterTitle")}
            </Button>
          ) : null}

          {data ? (
            <FundraiserHomeComponent props={data} />
          ) : (
            <div
              style={{
                height: "5em",
              }}
            ></div>
          )}
        </div>
      </Spin>
    </div>
  );
}
export default FundraiserHome;
