import "./index.scss";
import { Form, Input, Button, Upload, Spin, message, Card, Space } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../../store";
import { useTranslation } from "react-i18next";
// 进入这里的编辑界面 只能修改无作品学校，个人，公司的基础信息
function FundraiserEdit({ onChangeTypeHandler, data }) {
  const { loginStore, uploadStore, fundraiserStore } = useStore();
  const { t, i18n } = useTranslation();
  const [userFileList, setUserFileList] = useState([]);
  const [campaignFileList, setCampaignFileList] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [kind, setKind] = useState("campaign/avatar");
  const [uploadType, setUploadType] = useState("avatar");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data) {
      if (data.avatarUrl) {
        // 作品头像
        setUserFileList([
          {
            id: data.avatarAssetId,
            status: "done",
            url: data.avatarUrl,
            name: "avatar",
          },
        ]);
      }

      if (data.assetsUrl) {
        let list = [];
        data.assetsUrl.map((asset) => {
          if (asset.assetId) {
            list.push({
              id: asset.assetId,
              status: "done",
              url: asset.url,
              name: "campaign",
            });
          }
        });
        setCampaignFileList(list);
      }
    }
  }, [data]);

  // 改变上传路径
  const onChangeKind = (value) => {
    if (value === "campaign/asset") {
      setUploadType("asset");
    } else {
      setUploadType("avatar");
    }
    setKind(value);
  };

  // 上传图片
  const uploadCustomRequest = async (options) => {
    const form = new FormData();
    form.append("file", options.file);

    try {
      setUploadLoading(true);
      const res = await uploadStore.uploadImage(form, kind);
      let data = {
        id: res.data.id,
        status: "done",
        url: res.data.url,
      };
      if (uploadType === "avatar") {
        setUserFileList([
          {
            ...data,
            name: "avatar",
          },
        ]);
      }

      if (uploadType === "asset") {
        setCampaignFileList([
          ...campaignFileList,
          {
            ...data,
            name: "asset",
          },
        ]);
      }
      setUploadLoading(false);
    } catch (error) {
      setUploadLoading(false);
    }
  };

  const uploadProps = {
    name: "file",
    showUploadList: {
      showPreviewIcon: false,
    },
    customRequest: uploadCustomRequest,
    listType: "picture-circle",
    onRemove: (e) => {
      if (userFileList.find((item) => item.id === e.id)) {
        setUserFileList([]);
      }
      if (campaignFileList.find((item) => item.id === e.id)) {
        let tmpList = [...campaignFileList];
        let index = campaignFileList.findIndex((item) => item.id === e.id);
        tmpList.splice(index, 1);
        setCampaignFileList(tmpList);
      }
    },
    beforeUpload: function (file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error(t("fundraiserPortalCampaignProfileImageType"));
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error(t("fundraiserPortalCampaignInfoWorksImagesSize"));
        return false;
      }
      return isJpgOrPng && isLt2M;
    },
  };

  const onFinish = async (values) => {
    try {
      let param = {};
      // 对比是否改变过
      if (
        values.nameChinese !== data?.name["zh-tw"] ||
        values.nameEnglish !== data?.name["en"]
      ) {
        param["name"] = {
          "zh-tw": values.nameChinese,
          en: values.nameEnglish,
        };
      }

      if (
        values.descriptionChinese !== data?.description["zh-tw"] ||
        values.descriptionEnglish !== data?.description["en"]
      ) {
        param["description"] = {
          "zh-tw": values.descriptionChinese,
          en: values.descriptionEnglish,
        };
      }

      if (
        values.quantity !== undefined &&
        Number(values.quantity) !== data?.quantity
      ) {
        param["quantity"] = Number(values.quantity);
      }

      if (
        values.campaignVideo !== "" &&
        values.campaignVideo !== data?.videoUrl
      ) {
        param["videoUrl"] = values.campaignVideo;
      }

      if (userFileList.length && data.avatarAssetId !== userFileList[0].id) {
        param["avatarAssetId"] = userFileList[0].id;
      }

      if (campaignFileList.length) {
        let tmpList = [];
        campaignFileList.map((item) => {
          tmpList.push(item.id);
        });
        if (JSON.stringify(data.assetIds) !== JSON.stringify(tmpList)) {
          param["assetIds"] = tmpList;
        }
      }

      if (Object.keys(param).length) {
        param["id"] = data.id;
        setLoading(true);
        await fundraiserStore.putCampaign(param);
      }
      onChangeTypeHandler("LIST");
      setLoading(false);
    } catch (error) {
      console.log(error);
      message.error(error.response?.data || t("submitFailedMessage"));
    }
  };
  return (
    <Spin spinning={loading}>
      <Card
        size="small"
        bordered={false}
        bodyStyle={{
          boxShadow: "none",
        }}
      >
        <Form
          onFinish={onFinish}
          initialValues={{
            nameChinese: data?.name["zh-tw"],
            nameEnglish: data?.name["en"],
            quantity: data?.quantity,
            descriptionChinese: data?.description["zh-tw"],
            descriptionEnglish: data?.description["en"],
            campaignVideo: data?.videoUrl,
          }}
          layout="vertical"
        >
          <Form.Item
            label={t("fundraiserPortalInfoCampaignNameCN")}
            required
            name="nameChinese"
          >
            <Input></Input>
          </Form.Item>

          <Form.Item
            label={t("fundraiserPortalInfoCampaignNameEN")}
            required
            name="nameEnglish"
          >
            <Input></Input>
          </Form.Item>

          {data?.category === "INDIVIDUAL" ? null : (
            <Form.Item
              label={t("fundraiserPortalCampaignInfoProfileAvatar")}
              name="profileName"
            >
              <Upload
                {...uploadProps}
                fileList={userFileList}
                onClick={() => onChangeKind("campaign/avatar")}
              >
                {userFileList.length >= 1 ? null : (
                  <div>
                    {uploadLoading && uploadType == "avatar" ? (
                      <LoadingOutlined />
                    ) : (
                      <PlusOutlined />
                    )}
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      {t("uploadText")}
                    </div>
                  </div>
                )}
              </Upload>
            </Form.Item>
          )}

          {data?.category === "SCHOOL" ? (
            <Form.Item
              label={t("fundraiserPortalCampaignInfoQuantity")}
              name="quantity"
            >
              <Input></Input>
            </Form.Item>
          ) : null}

          <Form.Item
            label={t("fundraiserPortalCampaignInfoDescriptionCN")}
            required
            name="descriptionChinese"
          >
            <Input.TextArea></Input.TextArea>
          </Form.Item>
          <Form.Item
            label={t("fundraiserPortalCampaignInfoDescriptionEN")}
            required
            name="descriptionEnglish"
          >
            <Input.TextArea></Input.TextArea>
          </Form.Item>

          {data?.category === "SCHOOL" ? (
            <>
              <Form.Item
                label={t("fundraiserPortalCampaignInfoWorksImages")}
                name="campaignImage"
                style={{
                  margin: "0",
                }}
              >
                <Upload
                  {...uploadProps}
                  fileList={campaignFileList}
                  onClick={() => onChangeKind("campaign/asset")}
                >
                  {campaignFileList.length >= 4 ? null : (
                    <div>
                      {uploadLoading && uploadType === "asset" ? (
                        <LoadingOutlined />
                      ) : (
                        <PlusOutlined />
                      )}
                      <div
                        style={{
                          marginTop: 8,
                        }}
                      >
                        {t("uploadText")}
                      </div>
                    </div>
                  )}
                </Upload>
              </Form.Item>
              <Form.Item>
                <span
                  style={{
                    color: "#ee1c25",
                  }}
                >
                  **
                </span>
                {t("fundraiserPortalCampaignInfoWorksImagesSize")}
              </Form.Item>

              <Form.Item
                label={t("fundraiserPortalCampaignInfoWorksVideo")}
                name="campaignVideo"
                style={{
                  margin: "0",
                }}
              >
                <Input></Input>
              </Form.Item>

              <Form.Item>
                <span
                  style={{
                    color: "#ee1c25",
                  }}
                >
                  **
                </span>
                {t("fundraiserPortalCampaignInfoWorksVideoSize")}
              </Form.Item>
            </>
          ) : null}

          <Form.Item>
            <Space size={"large"}>
              <Button
                type="primary"
                shape="round"
                danger
                className="wide-button"
                htmlType="submit"
              >
                {t("btnSubmit")}
              </Button>

              <Button
                type="default"
                shape="round"
                danger
                className={["wide-button", "link-button"]}
                onClick={() => onChangeTypeHandler("LIST")}
              >
                {t("btnCancel")}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
}
export default observer(FundraiserEdit);
