import "./index.scss";
import DefaultAdvertSlider from "../Home/Default_Advert_Slider";
import DefaultCampaignDate from "../Home/Default_Campaign_Date";

import { Select, Spin, Space } from "antd";
import FundraiserHomeComponent from "../../components/FundraiserHomeComponent";
import { useEffect, useState } from "react";
import { useStore } from "../../store";
import { useTranslation } from "react-i18next";
function CanstructionJunior() {
  const { fundraiserStore } = useStore();
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  // 查询活动
  const searchCampaigns = async () => {
    setLoading(true);
    let campaignDatas = await fundraiserStore.getCampaign(search);
    let list = [];
    if (campaignDatas.data.count) {
      for (let item of campaignDatas.data.data) {
        let obj = {
          avatarSrc: item.avatarAssetId
            ? await fundraiserStore.getAssetUrl({
                id: item.avatarAssetId,
              })
            : null,
          name: item.name,
          raised: item.donationAmount === null ? "******" : item.donationAmount,
          //organizer: item.companyName || "",
          voteAmount: item.voteAmount,
          id: item.id,
          type: "SCHOOL",
        };
        list.push(obj);
      }
    }

    // 两个一行
    var arr = [];

    for (var i = 0; i < list.length; i += 2) {
      arr.push(list.slice(i, i + 2));
    }

    setData(arr);
    setLoading(false);
  };

  // 查询参数
  const [search, setSearch] = useState({
    sort: "voteAmount",
    order: "desc",
    isPublic: true,
    withCanstrction: true,
    category: "SCHOOL",
  });

  // 改变排序
  const onChangeSearchHandler = (value) => {
    let str = { ...search };
    switch (value) {
      case "Lastest":
        str = {
          ...str,
          sort: "createdAt",
          order: "desc",
        };
        break;
      case "Oldest":
        str = {
          ...str,
          sort: "createdAt",
          order: "asc",
        };
        break;
      case "Donate":
        str = {
          ...str,
          sort: "donationAmount",
          order: "desc",
        };
        break;
      case "Vote":
        str = {
          ...str,
          sort: "voteAmount",
          order: "desc",
        };
        break;
    }
    setSearch({
      ...str,
    });
  };

  useEffect(() => {
    (async () => {
      try {
        searchCampaigns();
      } catch (error) {
        console.log(error);
      }
    })();
  }, [search]);

  // check mobile
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <div className="canstruction-junior">
      <DefaultAdvertSlider />
      <DefaultCampaignDate />
      <div className="content-title-container">
        <Space>
          <h2 className="content-title">{t("menuCanstructionJunior")}</h2>
          <Select
            defaultValue="Vote"
            size={isMobile ? "small" : "large"}
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "16em",
            }}
            onChange={onChangeSearchHandler}
            options={[
              {
                value: "Lastest",
                label: t("lastestText"),
              },
              {
                value: "Oldest",
                label: t("oldestText"),
              },
              {
                value: "Donate",
                label: t("top10MostCashDonated"),
              },
              {
                value: "Vote",
                label: t("top10MostPopular"),
              },
            ]}
          />
        </Space>
      </div>

      <Spin spinning={loading}>
        <div className="container">
          {data ? (
            <FundraiserHomeComponent props={data} />
          ) : (
            <div
              style={{
                height: "5em",
              }}
            ></div>
          )}
        </div>
      </Spin>
    </div>
  );
}
export default CanstructionJunior;
