import "./index.scss";
import { Avatar, Button, Divider, Space, Typography, message } from "antd";
import { useStore } from "../../../store";
import { useEffect, useState } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
function DefaultShareYourDonationLink() {
  const navigate = useNavigate();
  const { loginStore, fundraiserStore } = useStore();
  const [linkData, setLinkData] = useState(null);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    (async () => {
      try {
        // 如果是公司，个人，或者学校(无campaign)
        let userData = toJS(loginStore).userData;

        if (
          userData.category === "INDIVIDUAL" ||
          userData.category === "CORPORATE"
        ) {
          let campaigns = await fundraiserStore.getCampaign({
            userId: userData.id,
            isPublic: true,
          });
          if (campaigns.data.data.length) {
            for (let item of campaigns.data.data) {
              let data = {
                name: item.name,
                avatarUrl: userData.avatarAssetId
                  ? await fundraiserStore.getAssetUrl({
                      id: userData.avatarAssetId,
                    })
                  : null,
                description: item.description,
                id: item.id,
              };
              setLinkData({ type: userData.category, list: data });
            }
          }
        }
        // 无作品学校先不做

        if (userData.category === "SCHOOL") {
          // 如果是学校，并且有campaign列表
          let list = await fundraiserStore.getCampaign({
            userId: userData.id,
            withCanstruction: true,
            isPublic: true,
          });

          if (list.data.data.length) {
            let tList = [];

            for (let item of list.data.data) {
              tList.push({
                ...item,
                name: item.name ? item.name : null,
                description: item.description ? item.description : null,
                avatarUrl: item.avatarAssetId
                  ? await fundraiserStore.getAssetUrl({
                      id: item.avatarAssetId,
                    })
                  : null,
              });
            }

            setLinkData({ type: userData.category, list: tList });
          }
        }
      } catch (error) {}
    })();
  }, []);
  return (
    <>
      {linkData ? (
        <div className="donation-link-content">
          {linkData?.type && linkData?.type !== "SCHOOL" ? (
            <>
              <h3 className="link-title">{t("homeShareDonationLinkTitle")}</h3>

              <div className="content-detail">
                <div>
                  <h2>
                    {linkData?.list?.name[i18n.language.replace("_", "-")]}
                  </h2>
                  <h3>
                    {
                      linkData?.list?.description[
                        i18n.language.replace("_", "-")
                      ]
                    }
                  </h3>

                  <CopyToClipboard
                    text={
                      window.location.origin + `/campaign/${linkData?.list?.id}`
                    }
                    onCopy={() => {
                      message.success("Copied!");
                    }}
                  >
                    <Button
                      type="primary"
                      shape="round"
                      size="large"
                      danger
                      className="link-button"
                      href={"/campaign/" + linkData?.list?.id}
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      {t("btnDonationLink")}
                    </Button>
                  </CopyToClipboard>
                </div>
              </div>
              <Divider className="divider-line" />
            </>
          ) : null}
          {linkData?.type && linkData?.type === "SCHOOL" ? (
            <>
              <h3 className="link-title">{t("homeShareDonationLinkTitle")}</h3>

              <div className="content-avatar">
                <Space size={50} wrap>
                  {linkData?.list?.map((item, index) => {
                    return (
                      <div className="avatar-logo" key={index}>
                        <Avatar
                          className="logo-detail-img"
                          size={{
                            xs: 100,
                            sm: 120,
                            md: 140,
                            lg: 160,
                            xl: 180,
                            xxl: 200,
                          }}
                          src={item.avatarUrl}
                        ></Avatar>
                        <h5>{item.name[i18n.language.replace('_','-')]}</h5>

                        <CopyToClipboard
                          text={window.location.origin + `/campaign/${item.id}`}
                          onCopy={() => {
                            message.success("Copied!");
                          }}
                        >
                          <Button
                            type="link"
                            shape="round"
                            danger
                            className="link-button"
                            href={"/campaign/" + item.id}
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {t("btnDonationLink")}
                          </Button>
                        </CopyToClipboard>
                      </div>
                    );
                  })}
                </Space>
              </div>
            </>
          ) : null}
        </div>
      ) : null}
    </>
  );
}
export default observer(DefaultShareYourDonationLink);
